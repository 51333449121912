import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './theme'
import { useFirebaseCollection, sortByIndex, sortByYear } from './utils'
import Routes from './routes'
import './index.css'

const App = () => {
  const [films] = useFirebaseCollection('films')
  const [grads] = useFirebaseCollection('grads')
  const [awards] = useFirebaseCollection('awards')
  const [about] = useFirebaseCollection('about')
  const data = {
    about: about,
    films: films.sort((a, b) => {
      if (
        typeof a.sort_index === 'number' ||
        typeof b.sort_index === 'number'
      ) {
        const aSort = typeof a.sort_index === 'number' ? a.sort_index : 99
        const bSort = typeof b.sort_index === 'number' ? b.sort_index : 99
        const result = aSort - bSort
        if (result !== 0) return result
      }
      return (+b.year || 2020) - (+a.year || 2020)
    }),
    awards: awards,
    grads: grads
      .sort(sortByIndex)
      .sort(sortByYear)
      .map((g) => ({
        ...g,
        avatar: g.avatar
          ? g.avatar
          : {
              downloadURL: `https://api.adorable.io/avatars/400/${g.id}`,
            },
        art_preview: g.art_preview
          ? g.art_preview
          : {
              downloadURL: `https://source.unsplash.com/weekly?water`,
            },
      })),
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Routes data={data} />
    </MuiThemeProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
