import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: 'GothamRounded-Light, Helvetica, Roboto, Arial',
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 600,
    h1: {
      fontSize: '50px',
      fontWeight: 'bold',
      letterSpacing: '-0.01rem',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '35px',
      lineHeight: '50px',
      letterSpacing: '-0.02em',
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '22px',
      lineHeight: '26px',
      letterSpacing: '-0.01em',
    },
    subtitle1: {
      fontFamily: 'GothamRounded-Medium',
      fontSize: '16px',
      lineHeight: '22px',
    },
    caption: {
      fontWeight: 'normal',
      fontSize: '40px',
      fontFamily: 'LiquidAmber',
      lineHeight: '120%',
      letterSpacing: '0.01em',
      textTransform: 'uppercase',
    },
    body1: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '140%',
      letterSpacing: '0.02em',
    },
    body2: {
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '100%',
      letterSpacing: '0.01em',
    },
  },
  palette: {
    primary: { main: '#e92722', contrastText: '#fff' },
    secondary: { main: '#fff', contrastText: '#000' },
  },
})
