import { useEffect, useState } from 'react'
import firebase from './firebase'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import YouTubeIcon from '@material-ui/icons/YouTube'
import BookIcon from '@material-ui/icons/Book'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import LanguageIcon from '@material-ui/icons/Language'
export function useFirebaseCollection(collectionName) {
  const [items, setItems] = useState([])
  useEffect(() => {
    firebase
      .firestore()
      .collection(collectionName)
      .onSnapshot((update) => {
        let collection = []

        if (update.size) {
          update.forEach((doc) =>
            collection.push({ ...doc.data(), id: doc.id }),
          )
        }

        setItems(collection)
      })
  }, [collectionName])
  return [items, setItems]
}

export const slugify = (s) =>
  s.toLowerCase().replace(/!|:|/g, '').replace('&', 'and').replace(/ +/g, '-')

export const gotoFilm = (history, film) =>
  film && history.push(`/films/${slugify(film.name)}`)

export const gotoGrad = (history, grad) =>
  grad && history.push(`/grads/${slugify(grad.name)}`)

export const sortByIndex = ({ sort_index: a = 99 }, { sort_index: b = 99 }) =>
  a - b

export const sortByYear = ({ year: a = 2020 }, { year: b = 2020 }) => b - a

export const SITE_ICONS = {
  personal: LanguageIcon,
  instagram: InstagramIcon,
  twitter: TwitterIcon,
  facebook: FacebookIcon,
  linkedin: LinkedInIcon,
  youtube: YouTubeIcon,
  tumblr: BookIcon,
  blogger: BookIcon,
  snapchat: PhotoCameraIcon,
  twitch: YouTubeIcon,
  vimeo: YouTubeIcon,
}
