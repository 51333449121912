import React from 'react'
import { Admin, Resource } from 'react-admin'
import { firestoreProvider } from './config/firestoreProvider'
import FirebaseAuthProvider from './config/firebaseAuthProvider'
import { FilmList, FilmEdit, FilmCreate } from './collections/films'
import { GradList, GradEdit, GradCreate } from './collections/grads'
import { AccountList, AccountEdit, AccountCreate } from './collections/accounts'
import { TagList, TagEdit, TagCreate } from './collections/tags'
import { AwardList, AwardEdit, AwardCreate } from './collections/awards'
import { AboutList, AboutEdit, AboutCreate } from './collections/abouts'
import { theme } from '../../theme'
import firebase from '../../firebase'

import './index.css'

const dataProvider = firestoreProvider(firebase)
const authProvider = FirebaseAuthProvider()

const AdminView = ({ history }) => {
  return (
    <Admin
      history={history}
      theme={theme}
      dataProvider={dataProvider}
      authProvider={authProvider}
      title="React Admin"
      loginPage={false}
    >
      {(permissions) => [
        <Resource
          name="admin/grads"
          options={{ label: 'Grads' }}
          list={GradList}
          edit={GradEdit}
          create={permissions === 'admin' && GradCreate}
        />,
        <Resource
          name="admin/gradAccounts"
          options={{ label: 'Users' }}
          list={permissions === 'admin' && AccountList}
          edit={permissions === 'admin' && AccountEdit}
          create={permissions === 'admin' && AccountCreate}
        />,
        <Resource
          name="admin/films"
          options={{ label: 'Films' }}
          list={permissions === 'admin' && FilmList}
          edit={permissions === 'admin' && FilmEdit}
          create={permissions === 'admin' && FilmCreate}
        />,
        <Resource
          name="admin/tags"
          options={{ label: 'Tags' }}
          list={permissions === 'admin' && TagList}
          edit={permissions === 'admin' && TagEdit}
          create={permissions === 'admin' && TagCreate}
        />,
        <Resource
          name="admin/awards"
          options={{ label: 'Awards' }}
          list={permissions === 'admin' && AwardList}
          edit={permissions === 'admin' && AwardEdit}
          create={permissions === 'admin' && AwardCreate}
        />,
        <Resource
          name="admin/about"
          options={{ label: 'About' }}
          list={permissions === 'admin' && AboutList}
          edit={permissions === 'admin' && AboutEdit}
          create={permissions === 'admin' && AboutCreate}
        />,
      ]}
    </Admin>
  )
}

export default AdminView
