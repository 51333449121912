import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { Typography, Box, ButtonBase } from '@material-ui/core'
import { TopLabel } from './TopLabel'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { Avatar } from '.'

export function Film({
  label,
  film = {},
  avatar,
  description,
  name,
  summary,
  extra,
  children,
}) {
  const [overlay, setOverlay] = useState(!film.video_id)
  const descriptionText = description || film.synopsis
  return (
    <>
      <Box position="relative" paddingTop="56.25%">
        {film.video_url ? (
          <ReactPlayer
            url={film.video_url}
            controls
            light={
              film.background_image ? film.background_image.downloadURL : null
            }
            playing={!!film.background_image}
            playIcon={
              <PlayArrowIcon
                color="primary"
                style={{ fontSize: 150, zIndex: 20 }}
              />
            }
            onPause={() => setOverlay(true)}
            onPlay={() => setOverlay(false)}
            className="player"
            height="100%"
            width="100%"
            options={{
              vimeo: { preload: true },
            }}
          />
        ) : (
          <Box
            clone
            position="absolute"
            bottom={0}
            top={0}
            right={0}
            left={0}
            style={{
              backgroundImage: `url(${
                film.background_image ? film.background_image.downloadURL : null
              })`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <ButtonBase
              component="div"
              onClick={() => film.alt_url && window.open(film.alt_url)}
            />
          </Box>
        )}

        {label && overlay && <TopLabel label={label} />}

        {overlay && (
          <Box
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            px={2}
            py={1}
            bgcolor="rgba(0,0,0,0.4)"
            display="flex"
            alignItems="center"
          >
            {avatar && (
              <Avatar
                image={avatar.downloadURL}
                style={{
                  marginRight: 16,
                  position: 'relative',
                  bottom: -25,
                  marginTop: -40,
                }}
              />
            )}
            <Box py={2}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  fontSize: 22,
                  color: 'white',
                }}
              >
                {name || film.name}
              </Typography>

              {extra}

              {summary && (
                <Typography
                  variant="body1"
                  style={{
                    color: 'white',
                    marginTop: 5,
                    fontWeight: 'bold',
                  }}
                >
                  {summary}
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Box>

      {(children || descriptionText) && (
        <Box p={3} bgcolor="white">
          {descriptionText && (
            <Typography dangerouslySetInnerHTML={{ __html: descriptionText }} />
          )}

          {children}
        </Box>
      )}
    </>
  )
}
