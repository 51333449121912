import * as React from 'react'
import {
  Datagrid,
  List,
  Create,
  Edit,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  NumberInput,
  ImageInput,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
  SelectInput,
  ArrayInput,
  ImageField,
  SimpleFormIterator,
  ShowButton,
  required,
  Toolbar,
} from 'react-admin'
import { Box } from '@material-ui/core'
import RichTextInput from 'ra-input-rich-text'
import { usePermissions } from 'react-admin'

const SOCIAL_MEDIA = [
  'Personal',
  'Instagram',
  'Twitter',
  'Facebook',
  'LinkedIn',
  'Youtube',
  'Tumblr',
  'Blogger',
  'Snapchat',
  'Twitch',
  'Vimeo',
]

const CreateEdit = () => {
  const { permissions } = usePermissions()
  return (
    <>
      {permissions === 'admin' && (
        <Box display="flex">
          <Box display="flex" flex={1}>
            <TextInput fullWidth validate={required()} source="name" />

            <Box mx={1} />

            <TextInput fullWidth validate={required()} source="email" />

            <Box mx={1} />

            <SelectInput
              required
              translateChoice={false}
              choices={[...Array(10).keys()].map((n) => {
                const id = new Date().getFullYear() - n + 1
                return { id, name: id }
              })}
              source="year"
              allowEmpty={false}
            />
          </Box>

          <Box mx={1} />

          <Box width={120}>
            <NumberInput fullWidth source="sort_index" />
          </Box>
        </Box>
      )}

      <Box display="flex">
        <TextInput source="video_url" fullWidth label="Demo Reel link" />
        <Box mx={1} />
        <TextInput fullWidth source="resume_pdf_url" />
        <Box mx={1} />
        <TextInput fullWidth source="artwork_pdf_url" />
      </Box>

      <Box display="flex">
        <Box flex={1}>
          <ReferenceArrayInput
            source="tags"
            label="Tags"
            reference="admin/tags"
            perPage={300}
            allowEmpty
          >
            <SelectArrayInput translateChoice={false} optionText="name" optionValue="name" fullWidth />
          </ReferenceArrayInput>
        </Box>
        <Box mx={1} />

        <Box width={240} display="flex" alignItems="center">
          <BooleanInput label="Fulltime" source="fulltime" />
          <Box mx={1} />
          <BooleanInput label="Parttime" source="parttime" />
        </Box>
      </Box>

      <RichTextInput label="Bio" source="bio" />

      <Box my={2} display="flex">
        <ImageInput source="avatar" label="Avatar" accept="image/*">
          <ImageField source="downloadURL" title="title" />
        </ImageInput>

        <Box mx={1} />
        <ImageInput
          source="art_preview"
          label="Art Resume Preview"
          accept="image/*"
        >
          <ImageField source="downloadURL" title="title" />
        </ImageInput>
        <Box mx={1} />

        <ImageInput
          source="video_background"
          label="Video image replacement (artwork preview will be used if not set)"
          accept="image/*"
        >
          <ImageField source="downloadURL" title="title" />
        </ImageInput>
      </Box>

      <ArrayInput source="social_links">
        <SimpleFormIterator>
          <SelectInput
            translateChoice={false}
            label="Site"
            source="site"
            choices={SOCIAL_MEDIA.map((sm) => ({
              id: sm.toLowerCase(),
              name: sm,
            }))}
          />
          <TextInput label="Url" source="url" />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  )
}

export const GradList = (props) => (
  <List {...props} perPage={300} sort={{ field: 'year', order: 'DESC' }}>
    <Datagrid>
      <TextField source="year" />
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="id" />
      <ShowButton label="" />
    </Datagrid>
  </List>
)

const SaveOnlyToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

export const GradEdit = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm redirect="show" toolbar={<SaveOnlyToolbar />}>
      <CreateEdit />
    </SimpleForm>
  </Edit>
)

export const GradCreate = (props) => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <CreateEdit />
    </SimpleForm>
  </Create>
)
