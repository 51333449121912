import React from 'react'
import { Box, ButtonBase } from '@material-ui/core'
import { TopLabel } from './TopLabel'

export function SideContent({ onClick, label, image, children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      width="100%"
      maxWidth={500}
      mt={{ xs: 2, sm: 0 }}
      mx={{ xs: 0, sm: 2 }}
    >
      <Box
        clone
        flex={1}
        paddingTop="100%"
        position="relative"
        bgcolor="gray"
        style={{
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            image && image.downloadURL
              ? `url(${image.downloadURL})`
              : 'url(https://source.unsplash.com/user/erondu)',
        }}
      >
        <ButtonBase onClick={onClick}>
          {label && <TopLabel label={label} />}
        </ButtonBase>
      </Box>

      {children && <Box bgcolor="white">{children}</Box>}
    </Box>
  )
}
