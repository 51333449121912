import React, { useEffect, useState } from 'react'
import { slugify, gotoGrad, SITE_ICONS } from '../utils'
import { Frame, ResponsiveFrame } from '../components/Frame'
import { Film } from '../components/Film'
import {
  Box,
  IconButton,
  FormControlLabel,
  Checkbox,
  makeStyles,
  ButtonBase,
  Typography,
} from '@material-ui/core'
import resume from '../images/resume.png'
import { Breadcrumb } from '../components/Breadcrumb'
import CheckIcon from '@material-ui/icons/CheckCircle'
import UncheckIcon from '@material-ui/icons/Cancel'
import { TopLabel } from '../components/TopLabel'
const useLabelStyles = makeStyles({
  root: { pointerEvents: 'none' },
  label: { fontSize: 13, fontFamily: 'GothamRounded-Medium', color: '#444' },
})

const GradPage = ({ history, grads, match, films }) => {
  const grad = grads.find((s) => slugify(s.name) === match.params.grad)
  if (!grad) {
    return <Frame bgcolor="#ddd"></Frame>
  }

  const gradIndex = grads.indexOf(grad)
  const prevGrad = grads[gradIndex - 1] || grads[grads.length - 1]
  const nextGrad = grads[gradIndex + 1] || grads[0]

  const filmContent =
    {
      video_url: grad.video_url,
      alt_url: grad.artwork_pdf_url,
      background_image: grad.video_background,
    } || films[0]
  return (
    <ResponsiveFrame
      minHeight="85vh"
      sideContent={<GradSideContent grad={{ ...grad }} />}
      header={
        <Breadcrumb
          label={grad.name}
          href="/grads"
          linkText="Meet the Grads"
          onNext={() => gotoGrad(history, nextGrad)}
          onPrev={() => gotoGrad(history, prevGrad)}
        />
      }
    >
      <Film
        film={filmContent}
        avatar={grad.avatar}
        description={grad.bio}
        label={filmContent.video_url && 'DEMO REEL'}
        name={`${grad.name}`}
        summary={grad.tags.join(', ')}
      />
    </ResponsiveFrame>
  )
}

export default GradPage

function GradSideContent({ grad }) {
  const [email, setEmail] = useState(grad.email)
  const [parttime, setParttime] = useState(grad.parttime)
  const [fulltime, setFulltime] = useState(grad.fulltime)

  useEffect(() => {
    setEmail(grad.email)
    setParttime(grad.parttime)
    setFulltime(grad.fulltime)
  }, [grad])
  return (
    <Box mt={{ xs: 2, sm: 0 }} ml={{ xs: 0, sm: 2 }}>
      <TopLabel label="Artwork" absolute={false} />
      <ButtonBase onClick={() => window.open(grad.artwork_pdf_url, '_blank')}>
        {grad.art_preview && (
          <img alt="Art" width="100%" src={grad.art_preview.downloadURL} />
        )}
      </ButtonBase>

      <Box
        bgcolor="white"
        p={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          alignSelf="stretch"
        >
          <ButtonBase
            onClick={() => window.open(grad.resume_pdf_url, '_blank')}
            style={{ marginRight: 20 }}
          >
            <img width={50} src={resume} alt="resume" />
          </ButtonBase>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <CustomCheckbox
              label="Available"
              checked={fulltime}
              color={fulltime ? 'rgb(57,169,75)' : 'rgb(204,212,36)'}
              name="available"
            />
            <CustomCheckbox
              label="Freelance"
              checked={parttime}
              color={parttime ? 'rgb(57,169,75)' : 'rgb(204,212,36)'}
              name="freelance"
            />
          </Box>
        </Box>
      </Box>

      <Box bgcolor="#fff" display="flex" justifyContent="center">
        <Typography
          onClick={() =>
            navigator.clipboard.writeText(grad.email).then(() => {
              setEmail('Email copied!')
              setTimeout(() => setEmail(grad.email), 1500)
            })
          }
          style={{ marginBottom: 8, fontSize: 16 }}
        >
          {email}
        </Typography>
      </Box>

      {grad.social_links && (
        <Box bgcolor="#444" display="flex" justifyContent="center">
          {grad.social_links.map((link) => {
            const SiteIcon = SITE_ICONS[link.site]
            return (
              <IconButton
                key={link.site}
                onClick={() => window.open(link.url, '_blank')}
                style={{ padding: '4px 6px' }}
              >
                <SiteIcon style={{ fontSize: 28, fill: 'white' }} />
              </IconButton>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

const CustomCheckbox = ({ label, checked, color }) => {
  const labelClasses = useLabelStyles()
  return (
    <FormControlLabel
      classes={labelClasses}
      label={label}
      control={
        <Checkbox
          icon={<UncheckIcon style={{ fill: color }} />}
          checkedIcon={<CheckIcon style={{ fill: color }} />}
          checked={checked}
          name="checkedH"
          style={{ padding: 2, paddingRight: 8 }}
        />
      }
    />
  )
}
