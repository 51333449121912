import React from 'react'
import { ResponsiveFrame } from '../components/Frame'
import { Box, Typography } from '@material-ui/core'
import { Film } from '../components/Film'
import { slugify } from '../utils'
import { useHistory } from 'react-router-dom'
import { SideContent } from '../components/SideContent'
import { GradList } from './GradListPage'
import { FilmList } from './FilmListPage'
import { ExtraFilmList } from './ExtraPage'

const Home = ({ history, grads, films }) => {
  const [filmOne, filmTwo, filmThree, filmFour] = films.filter(
    (f) => f.featured,
  )
  return (
    <ResponsiveFrame
      header={
        filmOne &&
        filmOne.video_news && (
          <Box
            display={{ xs: 'none', sm: 'flex' }}
            alignItems="center"
            mb={3}
            bgcolor="primary.main"
          >
            <Box display="flex">
              <Box display="flex" pl={2} bgcolor="#414141" alignItems="center">
                <Typography variant="caption" style={{ color: 'white' }}>
                  NEWS
                </Typography>
              </Box>
              <Box
                width={0}
                height={0}
                mr={2}
                style={{
                  border: '0 solid transparent',
                  borderBottomWidth: 28,
                  borderTopWidth: 28,
                  borderLeft: '14px solid #414141',
                }}
              />
            </Box>
            <Typography variant="subtitle1" style={{ color: 'white' }}>
              {filmOne.video_news}
            </Typography>
            <Box ml={8} />
          </Box>
        )
      }
      sideContent={
        filmOne && filmOne.video_id ? (
          <Box maxHeight={700} flex={1} ml={2}>
            <iframe
              allowfullscreen=""
              frameborder="0"
              title="livechat"
              width="100%"
              height="100%"
              src={`https://www.youtube.com/live_chat?v=${filmOne.video_id}&embed_domain=seneca2d.com`}
            ></iframe>
          </Box>
        ) : filmOne && filmOne.promo_image ? (
          <Box ml={2}>
            <img
              width="100%"
              alt="promo"
              src={filmOne.promo_image.downloadURL}
            />
          </Box>
        ) : (
          <Box maxHeight={700}>
            <Box my={{ xs: 1, sm: 0 }} />
            <FeaturedFilm film={filmTwo} />
            <Box my={1} />
            <FeaturedFilm film={filmThree} />
            <Box my={1} />
            <FeaturedFilm film={filmFour} />
          </Box>
        )
      }
      below={
        <>
          <FilmList films={films} />
          <GradList grads={grads} />
          <ExtraFilmList films={films} />
        </>
      }
    >
      <Box mt={{ xs: 4, sm: 0 }}>
        <Film
          label={filmOne && filmOne.video_id ? 'LIVE!' : 'FEATURE'}
          film={filmOne}
        />
      </Box>
    </ResponsiveFrame>
  )
}

export default Home

function FeaturedFilm({ film }) {
  const history = useHistory()
  if (!film) {
    return false
  }
  return (
    <SideContent
      image={film.background_image}
      label={film.name}
      onClick={() => history.push('/films/' + slugify(film.name))}
    />
  )
}
