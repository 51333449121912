import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import Admin from './containers/admin'
import LoginView from './containers/admin/Login'
import Home from './containers/HomePage'
import Film from './containers/FilmPage'
import About from './containers/AboutPage'
import Grad from './containers/GradPage'
import FilmList from './containers/FilmListPage'
import GradList from './containers/GradListPage'
import Extra from './containers/ExtraPage'
import firebase from './firebase'

export default function Routes({ props, data }) {
  return (
    <Router>
      <Switch>
        <Route
          path="/admin"
          render={(props) => <Admin {...props} {...data} />}
        />
        <Route
          exact
          path="/"
          render={(props) => <Home {...props} {...data} />}
        />
        <Route
          exact
          path="/films"
          render={(props) => <FilmList {...props} {...data} />}
        />
        <Route
          path="/films/:film"
          render={(props) => <Film {...props} {...data} />}
        />
        <Route
          exact
          path="/grads"
          render={(props) => <GradList {...props} {...data} />}
        />
        <Route
          path="/grads/:grad"
          render={(props) => <Grad {...props} {...data} />}
        />
        <Route
          exact
          path="/extra"
          render={(props) => <Extra {...props} {...data} />}
        />
        <Route
          exact
          path="/about"
          render={(props) => <About {...props} {...data} />}
        />
        <Route
          exact
          path="/login"
          render={(props) => <LoginView {...props} {...data} />}
        />
        <Route
          exact
          path="/logout"
          render={() => {
            firebase.auth().signOut()
            return <Redirect to="/login" />
          }}
        />
      </Switch>
    </Router>
  )
}
