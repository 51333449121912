import React from 'react'
export const Avatar = ({ image, size = 120, style = {} }) => (
  <div
    alt="avatar"
    style={{
      width: size,
      height: size,
      borderRadius: size / 2,
      ...style,
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  />
)
