import React, { useState } from 'react'
import { Frame } from '../components/Frame'
import { gotoFilm } from '../utils'
import { ImageList, ImageListItem } from '../components/ImageList'
import { useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { TopFilter } from '../components/TopFilter'

const FilmListPage = ({ history, films }) => {
  return (
    <Frame p={3}>
      <FilmList films={films} showFilters />
    </Frame>
  )
}

export default FilmListPage

export function FilmList({ films }) {
  const items = [...Array(10).keys()].map((n) =>
    (new Date().getFullYear() - n + 1).toString(),
  )
  const [active, setActive] = useState(items)
  const history = useHistory()
  const filteredFilms = films.filter(
    (film) =>
      active.includes(film.year ? film.year.toString() : '2020') && !film.extra,
  )
  return (
    <Box mt={2} pt={4} flex={1}>
      <ImageList
        label="Watch the Films"
        gridTemplateColumns={{
          xs: `repeat(1, minmax(0, 1fr))`,
          sm: `repeat(2, minmax(0, 1fr))`,
          md: `repeat(3, minmax(0, 1fr))`,
        }}
        filter={
          <TopFilter items={items} setActive={setActive} active={active} />
        }
      >
        {filteredFilms.map((film, index) => (
          <ImageListItem
            key={index}
            index={index}
            image={film.background_image}
            onClick={() => gotoFilm(history, film)}
            label={film.name}
            description={`${film.directorName ? film.directorName + ', ' : ''} ${film.year}`}
          />
        ))}
      </ImageList>
    </Box>
  )
}
