import React, { useState } from 'react'
import { Box, Link, Typography } from '@material-ui/core'
import { Header } from './Header'
import { Sidebar } from './Sidebar'

export function Frame({ children, header, ...props }) {
  const [open, setOpen] = useState(false)
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="80vh"
        bgcolor="#ddd"
        pb={2}
        {...props}
      >
        <Sidebar open={open} onClose={() => setOpen(false)} />
        <Header onClick={() => setOpen(true)} />
        <Box style={{ marginTop: 90 }} />
        {header}
        <Box display="flex" flex={1} px={2} {...props}>
          {children}
        </Box>
      </Box>

      <Box bgcolor="primary.main">
        <Box display="flex" px={4} py={2}>
          <Typography variant="body1" style={{ fontSize: 11, color: 'white' }}>
            © {new Date().getFullYear()} Seneca
          </Typography>
          <Box mx={1} />
          <Link
            variant="body1"
            href="https://www.senecacollege.ca/programs/fulltime/ANI.html"
            target="_blank"
            style={{ fontSize: 11, color: 'white' }}
          >
            Apply to Seneca!
          </Link>
          <Box mx={1} />
          <Link
            variant="body1"
            href="mailto:barney.wornoff@senecacollege.ca"
            style={{ fontSize: 11, color: 'white' }}
          >
            Contact
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

export const ResponsiveFrame = ({
  children,
  header,
  sideContent,
  below,
  ...props
}) => {
  return (
    <Frame header={header} display="flex" flexDirection="column" {...props}>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
        <Box flex={3}>{children}</Box>

        {sideContent && (
          <Box display="flex" flexDirection="column" flex={1}>
            {sideContent}
          </Box>
        )}
      </Box>
      {below}
    </Frame>
  )
}
