import * as React from 'react'
import {
  Datagrid,
  List,
  Create,
  Edit,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  Toolbar,
  BooleanField,
  ReferenceField,
  BooleanInput,
  SelectInput,
  ReferenceInput,
} from 'react-admin'
import { usePermissions } from 'react-admin'

const CreateEdit = () => {
  const { permissions } = usePermissions()
  return (
    permissions === 'admin' && (
      <>
        <TextInput fullWidth source="id" />
        <ReferenceInput label="Grad" source="grad" reference="admin/grads">
          <SelectInput
            translateChoice={false}
            optionText="name" />
        </ReferenceInput>
        <BooleanInput fullWidth source="admin" />
      </>
    )
  )
}

export const AccountList = (props) => (
  <List {...props} perPage={300}>
    <Datagrid>
      <TextField label="Firebase Account ID" source="id" />
      <ReferenceField label="Grad" source="grad" reference="admin/grads">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="admin" />
      <ShowButton label="" />
    </Datagrid>
  </List>
)

const SaveOnlyToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

export const AccountEdit = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm redirect="show" toolbar={<SaveOnlyToolbar />}>
      {console.log(props)}
      <CreateEdit />
    </SimpleForm>
  </Edit>
)

export const AccountCreate = (props) => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <CreateEdit />
    </SimpleForm>
  </Create>
)
