import * as React from 'react'
import {
  Datagrid,
  List,
  Create,
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  ImageInput,
  ImageField,
  required,
} from 'react-admin'

const CreateEdit = () => (
  <>
    <TextInput
      fullWidth
      validate={required()}
      label="Award Name"
      source="name"
    />
    <TextInput
      fullWidth
      validate={required()}
      label="Festival Name"
      source="festival"
    />
    <ImageInput source="festivalImage" label="Award image" accept="image/*">
      <ImageField source="downloadURL" title="title" />
    </ImageInput>
  </>
)

export const AwardList = (props) => (
  <List {...props} perPage={300}>
    <Datagrid>
      <TextField source="name" />
      <ShowButton label="" />
    </Datagrid>
  </List>
)

export const AwardEdit = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <CreateEdit />
    </SimpleForm>
  </Edit>
)

export const AwardCreate = (props) => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <CreateEdit />
    </SimpleForm>
  </Create>
)
