import React from 'react'
import { Box, Drawer, List, ListItem, ListItemText } from '@material-ui/core'
import { SocialMediaLinks } from './SocialMediaLinks'
import { useHistory } from 'react-router-dom'

export const Sidebar = ({ open, onClose }) => {
  const history = useHistory()
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <List>
        <ListItem button component="a" onClick={() => history.push('/')}>
          <ListItemText primary="FEATURE" />
        </ListItem>
        <ListItem button component="a" onClick={() => history.push('/about')}>
          <ListItemText primary="ABOUT" />
        </ListItem>
        <ListItem button component="a" onClick={() => history.push('/grads')}>
          <ListItemText primary="MEET THE GRADS" />
        </ListItem>
        <ListItem button component="a" onClick={() => history.push('/films')}>
          <ListItemText primary="WATCH THE FILMS" />
        </ListItem>
        <ListItem button component="a" onClick={() => history.push('/extra')}>
          <ListItemText primary="BEHIND THE SCENES" />
        </ListItem>
      </List>
      <Box mx={2} my={4}>
        <SocialMediaLinks fill="black" />
      </Box>
    </Drawer>
  )
}
