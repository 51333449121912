import React from 'react'
import { Typography, Box, Button, Link } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

export const Breadcrumb = ({ label, href, linkText, onPrev, onNext }) => {
  const history = useHistory()
  return (
    <Box
      display="flex"
      flex={1}
      mt={1}
      justifyContent="space-between"
      alignItems="center"
    >
      {onPrev && onNext && (
        <Button onClick={onPrev}>
          <ChevronLeftIcon />
          Previous
        </Button>
      )}
      <Typography>
        <Link
          variant="body2"
          onClick={() => history.push(href)}
          color="primary"
          style={{ textDecoration: 'none' }}
        >
          {linkText}
        </Link>{' '}
        / {label}
      </Typography>
      {onPrev && onNext && (
        <Button onClick={onNext}>
          Next <ChevronRightIcon />
        </Button>
      )}
    </Box>
  )
}
