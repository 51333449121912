import React from 'react'
import { Frame } from '../components/Frame'
import { useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { ImageList, ImageListItem } from '../components/ImageList'
import { gotoFilm } from '../utils'

const ExtraPage = ({ films }) => {
  return (
    <Frame minHeight="85vh">
      <ExtraFilmList films={films} />
    </Frame>
  )
}

export default ExtraPage

export function ExtraFilmList({ films }) {
  const history = useHistory()
  const filteredFilms = films
    .filter((film) => !!film.extra)
    .sort((a, b) => {
      return (+b.year || 2020) - (+a.year || 2020)
    })
  const numExtra = 3 - (filteredFilms.length % 3)
  const extra = new Array(numExtra === 3 ? 0 : numExtra).fill('')
  return (
    <Box mt={2} pt={4} flex={1}>
      <ImageList label="Behind the scenes"
        gridTemplateColumns={{
          xs: `repeat(1, minmax(0, 1fr))`,
          sm: `repeat(2, minmax(0, 1fr))`,
          md: `repeat(3, minmax(0, 1fr))`,
        }}>
        {[...filteredFilms, ...extra].map((film, index) => (
          <ImageListItem
            key={index}
            index={index}
            image={film.background_image}
            onClick={() => gotoFilm(history, film)}
            label={film.name}
            description={`${film.directorName ? film.directorName + ', ' : ''
              } ${film.year}`}
          />
        ))}
      </ImageList>
    </Box>
  )
}
