import React from 'react'
import { Box, ButtonBase, Typography } from '@material-ui/core'

export function ImageList({ children, gridTemplateColumns, label, filter, header }) {
  return (
    <>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        mb={{ xs: 1, sm: 0 }}
        justifyContent="space-between"
      >
        {label && (
          <Box bgcolor="primary.main" px={2} py={0}>
            <Typography variant="caption" style={{ color: 'white' }}>
              {label}
            </Typography>
          </Box>
        )}
        {filter}
      </Box>
      {header}
      <Box
        display="grid"
        gridGap={8}
        gridTemplateColumns={gridTemplateColumns}
      >
        {children}
      </Box>
    </>
  )
}

export function ImageListItem({
  image,
  onClick,
  index,
  label,
  ratio = '56.25%',
  description,
  style = {},
}) {
  return (
    <Box
      clone
      position="relative"
      bgcolor="gray"
      style={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundImage: image ? `url(${image.downloadURL})` : '',
        ...style,
      }}
    >
      <ButtonBase onClick={onClick}>
        <Box height={0} paddingBottom={ratio}>
          {label && (
            <Box
              position="absolute"
              bottom={0}
              right={0}
              left={0}
              px={1}
              py={1}
              textAlign="left"
              bgcolor="rgba(0,0,0,0.5)"
            >
              <Typography variant="subtitle1" color="primary">
                {label}
              </Typography>
              {description && (
                <Typography style={{ color: 'white' }}>
                  {description}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </ButtonBase>
    </Box>
  )
}
