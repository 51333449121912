export const firestoreProvider = (firebase) => ({
  getList: makeGetList(firebase),
  getOne: makeGetOne(firebase),
  getMany: makeGetMany(firebase),
  getManyReference: makeGetManyReference(firebase),
  create: makeCreate(firebase),
  update: makeUpdate(firebase),
  updateMany: makeUpdateMany(firebase),
  delete: makeDelete(firebase),
  deleteMany: makeDeleteMany(firebase),
})

const makeGetList = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  const { page, perPage } = params.pagination
  const { field, order } = params.sort
  const collection = firebase
    .firestore()
    .collection(resource)
    .limit(page * perPage)
  var query =
    field === 'id' ? collection : collection.orderBy(field, order.toLowerCase())
  return query.get().then((QuerySnapshot) => {
    var totalCount = QuerySnapshot.docs.length
    var firstDocToDisplayCount =
      page === 1 ? 1 : Math.min((page - 1) * perPage, totalCount)
    var firstDocToDisplay = QuerySnapshot.docs.slice(firstDocToDisplayCount - 1)
    return {
      data: firstDocToDisplay.map((doc) => getDataWithId(doc)),
      total: totalCount,
    }
  })
}

const makeGetOne = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  return firebase
    .firestore()
    .collection(resource)
    .doc(params.id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return { data: getDataWithId(doc) }
      } else {
        throw new Error({ message: 'No such doc', status: 404 })
      }
    })
    .catch((error) => {
      throw new Error({ message: error, status: 404 })
    })
}

const makeGetMany = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  return Promise.all(
    params.ids.map((id) =>
      firebase.firestore().collection(resource).doc(id).get(),
    ),
  ).then((arrayOfResults) => {
    return {
      data: arrayOfResults.map((documentSnapshot) =>
        getDataWithId(documentSnapshot),
      ),
    }
  })
}

const makeGetManyReference = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  const { target, id } = params
  const { field, order } = params.sort
  return firebase
    .firestore()
    .collection(resource)
    .where(target, '==', id)
    .orderBy(field, order.toLowerCase())
    .get()
    .then((QuerySnapshot) => {
      return {
        data: QuerySnapshot.docs.map((DocumentSnapshot) =>
          getDataWithId(DocumentSnapshot),
        ),
        total: QuerySnapshot.docs.length,
      }
    })
}

const makeCreate = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  var listOfFiles = Object.keys(params.data).filter(
    (key) => params.data[key] && params.data[key].rawFile,
  )

  if (params.previousData) {
    Object.entries(params.previousData)
      .filter(([key, value]) => value && value.downloadURL)
      .forEach(([key, value]) => {
        const current = params.data[key] || {}
        const previous = params.previousData[key] || {}
        const downloadURL = current.downloadURL
        const previousDownloadURL = previous.downloadURL
        if (downloadURL !== previousDownloadURL) {
          firebase
            .storage()
            .ref()
            .child(`${resource}/${value.title}`)
            .delete()
            .then(function () {
              console.log('deleted', value.title, 'successfully')
            })
            .catch(function (error) {
              console.error(error)
            })
        }
      })
  }

  return Promise.all(
    listOfFiles.map((key) => {
      return createOrUpdateFile(
        resource,
        params.data[key].rawFile,
        firebase,
      ).then((downloadURL) => {
        return { key: key, downloadURL: downloadURL }
      })
    }),
  ).then((arrayOfResults) => {
    arrayOfResults.map((keyAndUrl) => {
      delete params.data[keyAndUrl.key].rawFile
      params.data[keyAndUrl.key].downloadURL = keyAndUrl.downloadURL
      return params.data
    })

    return firebase
      .firestore()
      .collection(resource)
      .add(params.data)
      .then((DocumentReference) =>
        DocumentReference.get().then((DocumentSnapshot) => {
          return { data: getDataWithId(DocumentSnapshot) }
        }),
      )
  })
}

const makeUpdate = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  var listOfFiles = Object.keys(params.data).filter(
    (key) => params.data[key] && params.data[key].rawFile,
  )

  if (params.previousData) {
    Object.entries(params.previousData)
      .filter(([key, value]) => value && value.downloadURL)
      .forEach(([key, value]) => {
        const current = params.data[key] || {}
        const previous = params.previousData[key] || {}
        const downloadURL = current.downloadURL
        const previousDownloadURL = previous.downloadURL
        if (downloadURL !== previousDownloadURL) {
          firebase
            .storage()
            .ref()
            .child(`${resource}/${value.title}`)
            .delete()
            .then(function () {
              console.log('deleted', value.title, 'successfully')
            })
            .catch(function (error) {
              console.error(error)
            })
        }
      })
  }

  return Promise.all(
    listOfFiles.map((key) => {
      return createOrUpdateFile(
        resource,
        params.data[key].rawFile,
        firebase,
      ).then((downloadURL) => {
        return { key: key, downloadURL: downloadURL }
      })
    }),
  ).then((arrayOfResults) => {
    arrayOfResults.map((keyAndUrl) => {
      delete params.data[keyAndUrl.key].rawFile
      params.data[keyAndUrl.key].downloadURL = keyAndUrl.downloadURL
      return params.data
    })

    return firebase
      .firestore()
      .collection(resource)
      .doc(params.id)
      .set(params.data)
      .then(() => {
        return { data: params.data }
      })
  })
}

const makeUpdateMany = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  return params.ids.map((id) =>
    firebase
      .firestore()
      .collection(resource)
      .doc(id)
      .set(params.data)
      .then(() => id),
  )
}

const makeDelete = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  return firebase
    .firestore()
    .collection(resource)
    .doc(params.id)
    .delete()
    .then(() => {
      return { data: params.previousData }
    })
}

const makeDeleteMany = (firebase) => (_resource, params) => {
  const resource = _resource.replace('admin/', '')
  return {
    data: params.ids.map((id) =>
      firebase
        .firestore()
        .collection(resource)
        .doc(id)
        .delete()
        .then(() => id),
    ),
  }
}

function getDataWithId(DocumentSnapshot, { convertTime } = {}) {
  var dataWithId = {}
  const data = DocumentSnapshot.data()
  if (DocumentSnapshot) {
    dataWithId = {
      id: DocumentSnapshot.id,
      ...data,
    }
  }
  return dataWithId
}

async function uploadFileToBucket(rawFile, storageRef) {
  return storageRef
    .put(rawFile)
    .then((snapshot) => {
      return storageRef.getDownloadURL()
    })
    .catch((error) => {
      throw new Error({ message: error.message_, status: 401 })
    })
}

async function createOrUpdateFile(resource, rawFile, firebase) {
  var storageRef = firebase
    .storage()
    .ref()
    .child(resource + '/' + rawFile.name)
  return storageRef
    .getMetadata()
    .then((metadata) => {
      if (metadata && metadata.size === rawFile.size) {
        return storageRef.getDownloadURL()
      } else {
        return uploadFileToBucket(rawFile, storageRef)
      }
    })
    .catch(() => {
      return uploadFileToBucket(rawFile, storageRef)
    })
}
