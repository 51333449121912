import React from 'react'
import { slugify, gotoFilm } from '../utils'
import { Frame, ResponsiveFrame } from '../components/Frame'
import { Box, Typography, Link } from '@material-ui/core'
import { Film } from '../components/Film'
import AwardIcon from '@material-ui/icons/EmojiEvents'
import { Breadcrumb } from '../components/Breadcrumb'
import { Avatar } from '../components'
import { ImageListItem, ImageList } from '../components/ImageList'

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const FilmPage = ({ history, films, awards, match }) => {
  const film = films.find((s) => slugify(s.name) === match.params.film)

  if (!film) {
    return <Frame bgcolor="#ddd"></Frame>
  }

  const filmIndex = films.indexOf(film)
  const prevFilm = films[filmIndex - 1] || films[films.length - 1]
  const nextFilm = films[filmIndex + 1] || films[0]

  const thumbs = [film.thumb1, film.thumb2, film.thumb3].filter((t) => !!t)
  const filmAwards = awards.filter(
    (a) => film.awards && film.awards.includes(a.name),
  )

  return (
    <ResponsiveFrame
      minHeight="85vh"
      header={
        <Breadcrumb
          label={film.name}
          href="/films"
          linkText="Watch the Films"
          onNext={() => gotoFilm(history, nextFilm)}
          onPrev={() => gotoFilm(history, prevFilm)}
        />
      }
      sideContent={
        (!!film.promo_image || filmAwards.length > 0) && (
          <>
            {film.promo_image && (
              <Box mt={{ xs: 2, sm: 0 }} ml={{ xs: 0, sm: 2 }}>
                <img
                  width="100%"
                  alt="promo"
                  src={film.promo_image.downloadURL}
                />
              </Box>
            )}
            {film.promo_image && <Box my={1} />}
            <Box ml={{ sm: 2 }} display="flex" position="relative">
              {filmAwards.length > 0 && (
                <Box bgcolor="white" width="100%" pb={0}>
                  <Box bgcolor="primary.main" px={2} py={0}>
                    <Typography variant="caption" style={{ color: 'white' }}>
                      Festivals and Awards
                    </Typography>
                  </Box>

                  <Box px={2}>
                    {filmAwards.map((award) => (
                      <Box
                        key={award.name}
                        display="flex"
                        width="100%"
                        bgcolor="rgb(230,230,230)"
                        alignItems="center"
                        my={2}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          pr={1}
                        >
                          {award.festivalImage ? (
                            <Box
                              alt="award"
                              style={{
                                width: 60,
                                height: 60,
                                marginLeft: 12,
                                backgroundImage: `url(${award.festivalImage.downloadURL})`,
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                              }}
                            />
                          ) : (
                            <AwardIcon style={{ fontSize: 32 }} />
                          )}
                        </Box>
                        <Box py={1}>
                          <Typography variant="body1" style={{ marginLeft: 8 }}>
                            {award.name}
                          </Typography>
                          <Typography variant="body1" style={{ marginLeft: 8 }}>
                            {award.festival}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </>
        )
      }
    >
      <Film label={film.featured ? 'FEATURE' : ''} film={film}>
        {film.directorImage && film.directorBio && (
          <Box
            my={2}
            p={2}
            bgcolor="lightgrey"
            display="flex"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mr={2}
            >
              <Avatar image={film.directorImage.downloadURL} />
              <Typography style={{ marginTop: 8 }}>Director</Typography>
            </Box>
            <Typography
              dangerouslySetInnerHTML={{ __html: film.directorBio }}
            />
          </Box>
        )}
        {(film.credits || film.credits_override) && (
          <Typography style={{ fontWeight: 'bold', marginBottom: 5 }}>
            Credits
          </Typography>
        )}
        {film.credits &&
          film.credits.map((credit, index) => (
            <Box key={`credit-${index}`}>
              <Typography style={{ margin: '10px 0' }}>
                {capitalize(credit.role)}:{' '}
                {credit.grads.map((grad, index, arr) => (
                  <>
                    <Link key={`grad-${grad}`} href={`/grads/${slugify(grad)}`}>
                      {grad}
                    </Link>
                    {index !== arr.length - 1 ? ', ' : ''}
                  </>
                ))}
              </Typography>
            </Box>
          ))}

        {film.credits_override && (
          <Typography
            dangerouslySetInnerHTML={{ __html: film.credits_override }}
          />
        )}
      </Film>

      {thumbs.length > 0 && (
        <Box mt={2}>
          <ImageList
            gridTemplateColumns={{
              xs: `repeat(1, minmax(0, 1fr))`,
              sm: `repeat(2, minmax(0, 1fr))`,
              md: `repeat(3, minmax(0, 1fr))`,
            }}>
            {thumbs.map((image, index) => (
              <ImageListItem index={index} key={index} image={image} />
            ))}
          </ImageList>
        </Box>
      )}
    </ResponsiveFrame>
  )
}

export default FilmPage
