import React from 'react'
import { Typography, Box } from '@material-ui/core'

export const TopLabel = ({ absolute = true, label }) => (
  <Box
    position={absolute ? 'absolute' : 'static'}
    top={0}
    left={0}
    bgcolor="primary.main"
    px={2}
    py={0}
  >
    <Typography variant="caption" style={{ color: 'white' }}>
      {label}
    </Typography>
  </Box>
)
