import * as React from 'react'
import {
  Datagrid,
  List,
  Create,
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  required,
} from 'react-admin'

const CreateEdit = () => (
  <>
    <TextInput fullWidth validate={required()} label="Name" source="name" />
  </>
)

export const TagList = (props) => (
  <List {...props} perPage={300}>
    <Datagrid>
      <TextField source="name" />
      <ShowButton label="" />
    </Datagrid>
  </List>
)

export const TagEdit = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <CreateEdit />
    </SimpleForm>
  </Edit>
)

export const TagCreate = (props) => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <CreateEdit />
    </SimpleForm>
  </Create>
)
