import React from 'react'
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  useScrollTrigger,
  Slide,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import logo from '../images/logo.png'
import { useHistory } from 'react-router-dom'

const useToolbarStyles = makeStyles({
  root: {
    padding: '0 10px',
  },
})

const useAppbarStyles = makeStyles({
  root: {
    // boxShadow: 'none',
  },
})

export function Header({ onClick, headerLink = '/' }) {
  const history = useHistory()
  const toolbarClasses = useToolbarStyles()
  const appbarClasses = useAppbarStyles()

  return (
    <HideOnScroll>
      <AppBar
        color="white"
        classes={appbarClasses}
        position="fixed"
        style={{ backgroundColor: 'white' }}
      >
        <Toolbar disableGutters classes={toolbarClasses} variant="dense">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexGrow={1}
          >
            <Box display="flex" alignItems="center">
              <Typography
                style={{ cursor: 'pointer' }}
                component="a"
                onClick={() => history.push(headerLink)}
              >
                <img src={logo} alt="logo" height={85} />
              </Typography>
              <Box
                display={{ xs: 'none', sm: 'block' }}
                borderLeft="1px solid black"
                py={1.5}
                ml={2}
                pl={2}
              >
                <Typography>
                  Art and films of Seneca College's
                  <br />
                  2D animation grad students
                </Typography>
              </Box>
            </Box>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={onClick}
              style={{ marginLeft: 10 }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  )
}

function HideOnScroll(props) {
  const { children } = props
  const trigger = useScrollTrigger({ target: window })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}
