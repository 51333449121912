import React from 'react'
import { ResponsiveFrame } from '../components/Frame'
import { Box, Typography, IconButton } from '@material-ui/core'
import { Avatar } from '../components'
import { SITE_ICONS } from '../utils'
import aboutImage from '../images/about.jpg'

const AboutPage = ({ history, about }) => {
  console.log(about)
  return (
    <ResponsiveFrame minHeight="85vh" style={{ backgroundColor: 'white' }}>
      <Box m={3}>
        <Box mb={2} height={300} bgcolor="lightgray">
          <img
            alt="about"
            src={aboutImage}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>

        <Box mt={4} mb={4}>
          <Typography variant="h5">All about Seneca 2D Animation</Typography>
          <Box my={2} />
          <Typography>
            Seneca 2D is a website dedicated to collecting and displaying the
            films from the 2D students from Seneca College in Toronto Canada. In
            their third and final year at animation college, the 2D students
            work together to make these films. We hope you enjoy them. If you’re
            looking to hire a grad, check out the “Meet the Grads” page. Some
            are available for full time or freelance. You can filter students or
            grads by years or specialties with the{' '}
            <span style={{ color: 'red' }}>RED</span> ovals at the top of each
            of the pages. Thanks for visiting, and see you around!
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5">Seneca 2D Faculty</Typography>
          <Box my={2} />
          <Typography>
            The faculty of the 2D stream is made up of leading animation
            industry professionals. From animators to FX artists to background
            painters, character designers to riggers and builders, this talented
            team of specialized professors lend their skills to help mentor the
            students and their films.
          </Typography>
        </Box>

        {about
          .sort((a, b) => (a.index || 99) - (b.index || 99))
          .map((a) => (
            <Box
              key={a.name}
              my={2}
              p={2}
              bgcolor="#ddd"
              display="flex"
              alignItems="center"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mr={2}
              >
                {a.image && <Avatar image={a.image.downloadURL} />}
              </Box>
              <Box>
                <Typography style={{ marginTop: 8, fontWeight: 'bold' }}>
                  {a.name}
                </Typography>
                <Typography
                  dangerouslySetInnerHTML={{ __html: a.description }}
                />
                <Box display="flex">
                  {a.social_links && (
                    <Box display="flex">
                      {a.social_links.map((link) => {
                        const SiteIcon = SITE_ICONS[link.site]
                        return (
                          <IconButton
                            key={link.site}
                            onClick={() => window.open(link.url, '_blank')}
                            style={{ padding: '4px 6px' }}
                          >
                            <SiteIcon style={{ fontSize: 28, fill: 'black' }} />
                          </IconButton>
                        )
                      })}
                    </Box>
                  )}
                  <Typography
                    component="a"
                    href={`mailto:${a.email}`}
                    style={{ marginTop: 8 }}
                  >
                    {a.email}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    </ResponsiveFrame>
  )
}

export default AboutPage
