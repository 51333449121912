import * as React from 'react'
import {
  Datagrid,
  List,
  Create,
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  ImageInput,
  ImageField,
  required,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const SOCIAL_MEDIA = [
  'Personal',
  'Instagram',
  'Twitter',
  'Facebook',
  'LinkedIn',
  'Youtube',
  'Tumblr',
  'Blogger',
  'Snapchat',
  'Twitch',
  'Vimeo',
]

const CreateEdit = () => (
  <>
    <TextInput fullWidth validate={required()} label="Name" source="name" />
    <TextInput fullWidth validate={required()} label="Email" source="email" />
    <TextInput
      fullWidth
      validate={required()}
      label="Sort index"
      source="index"
    />
    <RichTextInput label="Description" source="description" />
    <ImageInput source="image" label="Avatar" accept="image/*">
      <ImageField source="downloadURL" title="title" />
    </ImageInput>
    <ArrayInput source="social_links">
      <SimpleFormIterator>
        <SelectInput
          label="Site"
          translateChoice={false}
          source="site"
          choices={SOCIAL_MEDIA.map((sm) => ({
            id: sm.toLowerCase(),
            name: sm,
          }))}
        />
        <TextInput label="Url" source="url" />
      </SimpleFormIterator>
    </ArrayInput>
  </>
)

export const AboutList = (props) => (
  <List {...props} perPage={300}>
    <Datagrid>
      <TextField source="name" />
      <ShowButton label="" />
    </Datagrid>
  </List>
)

export const AboutEdit = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <CreateEdit />
    </SimpleForm>
  </Edit>
)

export const AboutCreate = (props) => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <CreateEdit />
    </SimpleForm>
  </Create>
)
