import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from 'react-admin'
import firebase from 'firebase/app'

const config = {
  admin: {
    path: '/users/',
    validate: () => true,
  },
  keys: {
    permissions: 'permissions',
    token: 'firebase',
  },
  handleAuthStateChange: async (auth, config) => {
    if (auth) {
      const doc = await firebase
        .firestore()
        .collection('gradAccounts')
        .doc(auth.user.uid)
        .get()

      if (!config.admin.validate(doc.data())) {
        firebase.auth().signOut()
        return Promise.reject(
          new Error("Oops! You don't seem to be a authorized user"),
        )
      }
    }
  },
}

export default () => {
  const firebaseLoaded = () =>
    new Promise((resolve) => {
      firebase.auth().onAuthStateChanged(resolve)
    })

  return async (type, params) => {
    if (type === AUTH_LOGOUT) {
      await config.handleAuthStateChange(null, config)
      return firebase.auth().signOut()
    }

    if (firebase.auth().currentUser) {
      await firebase.auth().currentUser.reload()
    }

    if (type === AUTH_CHECK) {
      await firebaseLoaded()

      if (!firebase.auth().currentUser) {
        return Promise.reject(
          new Error("Oops! You don't seem to be signed in."),
        )
      }

      return true
    }

    if (type === AUTH_LOGIN) {
      const { username, password } = params
      let auth = firebase.auth().currentUser

      if (!auth) {
        try {
          auth = await firebase
            .auth()
            .signInWithEmailAndPassword(username, password)
        } catch (error) {
          return Promise.reject(error)
        }
      }

      return config.handleAuthStateChange(auth, config)
    }

    if (type === AUTH_GET_PERMISSIONS) {
      const data = localStorage.getItem(config.keys.permissions)
      return data
        ? Promise.resolve(data)
        : Promise.reject(new Error('Could not get permissions'))
    }

    return false
  }
}
