import React, { useEffect } from 'react'
import { Button, TextField, Box } from '@material-ui/core'
import firebase from '../../firebase'
import { useHistory } from 'react-router-dom'

const LoginView = () => {
  const history = useHistory()
  const user = firebase.auth().currentUser
  useEffect(() => {
    if (user) {
      history.push('/admin')
    }
  }, [history, user])

  return (
    <Box
      flex={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <form
        onSubmit={async (event) => {
          event.preventDefault()
          const { email, password } = event.target.elements
          await firebase
            .auth()
            .signInWithEmailAndPassword(email.value, password.value)
          const doc = await firebase
            .firestore()
            .collection('gradAccounts')
            .doc(firebase.auth().currentUser.uid)
            .get()

          const data = doc.data()
          localStorage.setItem('permissions', data.admin ? 'admin' : 'student')
          if (data.admin) {
            history.push('/admin/grads')
          } else {
            history.push(`/admin/grads/${data.grad}`)
          }
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <TextField name="email" label="Email Address" />
          <TextField name="password" type="password" label="Password" />

          <Button my={3} type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default LoginView
