import * as React from 'react'
import {
  Datagrid,
  List,
  Create,
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  SimpleFormIterator,
  ArrayInput,
  NumberInput,
  BooleanInput,
  ImageInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ImageField,
  ShowButton,
  required,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import { Box } from '@material-ui/core'

const CreateEdit = () => (
  <>
    <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
      <Box display="flex" flex={1} flexDirection={{ xs: 'column', sm: 'row' }}>
        <TextInput
          fullWidth
          validate={required()}
          label="Film title"
          source="name"
        />

        <Box mx={1} />

        <SelectInput
          translateChoice={false}

          choices={[...Array(10).keys()].map((n) => {
            const id = new Date().getFullYear() - n + 1
            return { id, name: id }
          })}
          fullWidth
          validate={required()}
          label="Film year"
          source="year"
        />

        <Box mx={1} />

        <TextInput
          fullWidth
          validate={required()}
          label="Video link"
          source="video_url"
        />
      </Box>

      <Box mx={1} />

      <Box width={80}>
        <NumberInput fullWidth label="Sort Index" source="sort_index" />
      </Box>
    </Box>

    <Box
      display="flex"
      alignItems="center"
      flexDirection={{ xs: 'column', sm: 'row' }}
    >
      <Box flex={1}>
        <BooleanInput label="Featured" source="featured" />
        <BooleanInput label="Behind the scenes" source="extra" />
      </Box>
      <Box flex={2}>
        <TextInput fullWidth label="Video id for chat" source="video_id" />
        <Box mx={1} />
        <TextInput fullWidth label="News for video" source="video_news" />
      </Box>
    </Box>

    <RichTextInput label="Synopsis" source="synopsis" />

    <Box display="flex">
      <ImageInput
        source="background_image"
        label="Background Image"
        accept="image/*"
      >
        <ImageField source="downloadURL" title="title" />
      </ImageInput>

      <Box mx={1} />

      <ImageInput source="promo_image" label="Poster Image" accept="image/*">
        <ImageField source="downloadURL" title="title" />
      </ImageInput>
    </Box>

    <Box display="flex">
      <ImageInput source="thumb1" label="Thumbnail 1" accept="image/*">
        <ImageField source="downloadURL" title="title" />
      </ImageInput>

      <Box mx={1} />

      <ImageInput source="thumb2" label="Thumbnail 2" accept="image/*">
        <ImageField source="downloadURL" title="title" />
      </ImageInput>

      <Box mx={1} />

      <ImageInput source="thumb3" label="Thumbnail 3" accept="image/*">
        <ImageField source="downloadURL" title="title" />
      </ImageInput>
    </Box>

    <Box display="flex">
      <Box flex={1}>
        <TextInput fullWidth label="Director name" source="directorName" />
        <ImageInput
          source="directorImage"
          label="Director Image"
          accept="image/*"
        >
          <ImageField source="downloadURL" title="title" />
        </ImageInput>
      </Box>

      <Box mx={1} />

      <Box flex={4}>
        <RichTextInput label="Director Bio" source="directorBio" />
      </Box>
    </Box>

    <ArrayInput source="credits">
      <SimpleFormIterator>
        <TextInput label="Role" source="role" />
        <ReferenceArrayInput
          label="Grads"
          source="grads"
          reference="admin/grads"
          perPage={300}
          allowEmpty
        >
          <SelectArrayInput
            translateChoice={false}
            optionText="name" optionValue="name" fullWidth />
        </ReferenceArrayInput>
      </SimpleFormIterator>
    </ArrayInput>

    <Box flex={4}>
      <RichTextInput label="Additional Credits" source="credits_override" />
    </Box>

    <ReferenceArrayInput
      source="awards"
      label="Awards"
      reference="admin/awards"
      perPage={300}
      allowEmpty
    >
      <SelectArrayInput
        translateChoice={false}
        optionText="name" optionValue="name" fullWidth />
    </ReferenceArrayInput>
  </>
)

export const FilmList = (props) => (
  <List {...props} sort={{ field: 'year', order: 'DESC' }} perPage={300}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="featured" />
      <TextField source="sort_index" />
      <TextField source="year" />
      <TextField source="runtime" />
      <ShowButton label="" />
    </Datagrid>
  </List>
)

export const FilmEdit = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <CreateEdit />
    </SimpleForm>
  </Edit>
)

export const FilmCreate = (props) => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <CreateEdit />
    </SimpleForm>
  </Create>
)
