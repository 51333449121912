import React, { useState } from 'react'
import { Frame } from '../components/Frame'
import { ImageList, ImageListItem } from '../components/ImageList'
import { gotoGrad } from '../utils'
import { useHistory } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'
import { TopFilter } from '../components/TopFilter'
import uniq from 'lodash/uniq'

const GradListPage = ({ grads }) => {
  return (
    <Frame p={3}>
      <GradList grads={grads} showFilters />
    </Frame>
  )
}

export default GradListPage


export function GradList({ grads }) {
  const items = uniq(
    grads
      .map((g) => g.tags)
      .flat()
      .filter((s) => !!s)
      .sort(),
  )
  const history = useHistory()
  const [active, setActive] = useState([])

  const filtered = grads.filter((grad) => {
    const tags = grad.tags || []
    return active.length === 0 || active.some((a) => tags.find((t) => t === a))
  })

  return (
    <Box mt={2} pt={4} flex={1}>
      <Typography style={{ textAlign: 'right', marginBottom: 20 }}>
        Click a skill below to sort grads by their specialties
      </Typography>
      <ImageList
        label="Meet the Grads"
        gridTemplateColumns={{
          xs: `repeat(1, minmax(0, 1fr))`,
          sm: `repeat(3, minmax(0, 1fr))`,
          md: `repeat(6, minmax(0, 1fr))`,
          lg: `repeat(8, minmax(0, 1fr))`,
        }}
        filter={
          <TopFilter
            items={items}
            setActive={setActive}
            active={active || []}
          />
        }
      >
        {filtered.map((grad, index) => (
          <ImageListItem
            key={grad.name}
            index={index}
            image={grad.avatar}
            ratio="100%"
            onClick={() => gotoGrad(history, grad)}
            label={grad.name ? `${grad.name} (${grad.year || '2020'})` : null}
          // description={grad.tags && grad.tags.join(', ')}
          />
        ))}
      </ImageList>
    </Box>
  )
}
