import React from 'react'
import { Box, Chip } from '@material-ui/core'

export function TopFilter({ active = [], setActive, items }) {
  return (
    <Box
      flexWrap={{ xs: 'wrap', sm: 'no-wrap' }}
      display="flex"
      justifyContent="flex-end"
    >
      {items.map((item) => (
        <Chip
          key={item}
          label={item}
          color={active.includes(item) ? 'primary' : 'null'}
          onClick={() =>
            active.includes(item) ? setActive([]) : setActive([item])
          }
          clickable
          style={{ marginTop: 8, marginLeft: 8 }}
        />
      ))}
    </Box>
  )
}
